import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Back from "../puresComponents/Back";
import ShareComp from "../components/ShareComp";
import FooterB2B from "../puresComponents/FooterB2B";
import FooterGleeph from "../puresComponents/FooterGleeph";
import helpers from "../utils/APIHelper";
import cst from "../utils/constant";
import MentionsLegales from "../puresComponents/MentionsLegales";
import ReglementConcours from "../puresComponents/ReglementConcours";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";

export default function ShareScreen() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [emailIsSend, setEmailIsSend] = useState(false);
  const [glidRef] = useState(searchParams.get("book"));
  const [typeOfSearch] = useState(searchParams.get("typeOfSearch"));
  const [isToggle, setIsToggle] = useState(false);

  function sendMail(mail1IsToggled) {
    const res = helpers.fetchSendMailReco(
      email,
      glidRef,
      typeOfSearch,
      mail1IsToggled
    );
    sendUserTracking(JSON.stringify({ mail1: mail1IsToggled }), "send_mail");
    setEmailIsSend(res);
  }

  function handlerChangeNameList(name) {
    setEmail(name);
  }

  function sendUserTracking(element, type) {
    helpers.fetchTrackSearch(type, element);
  }

  function clickOnToggle() {
    setIsToggle(!isToggle);
  }

  return (
    <div>
      <section>
        <Back />
        <ShareComp
          handlerChangeNameList={handlerChangeNameList}
          email={email}
          emailIsSend={emailIsSend}
          sendMail={sendMail}
          isToggle={isToggle}
          clickOnToggle={clickOnToggle}
        />
        <div className="separateLineStyle" />
        <FooterB2B />
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div>
          <div className="separateLineStyle" />
          <div className="containerPoliceLegal policeLegalShare">
            <div>
              *{cst.CLIENT_NAME} collecte et traite les données à caractère
              personnel de l’Utilisateur avec l’assistance de ses prestataires,
              y compris la société F-451, afin d’adresser les recommandations à
              l’Utilisateur et, le cas échéant, de lui adresser les lettres
              d’information des Editions {cst.CLIENT_NAME}. L’Utilisateur peut
              demander l’accès, la rectification, l’effacement et la portabilité
              de ses données. Il peut également s’opposer aux traitements ou
              demander la limitation des traitements à l’adresse :
            </div>
            <div>
              <a href={`mailto: ${cst.CLIENT_MAIL}`}>
                <span className="textUnderline">{cst.CLIENT_MAIL}</span>{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="separateLineStyle" />
        <ReglementConcours />
        <MentionsLegales />
        <PolitiqueDeConfident />
      </section>
    </div>
  );
}
