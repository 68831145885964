import text from "../utils/lang/lang";
import Button from "../puresComponents/Button";
import Cadeau from "../images/Cadeau.svg";
import cst from "../utils/constant";

const FooterButton = (props) => {
  return (
    <div className="footerContainer">
      <Button
        redirect={`/${cst.ID_STORE}/concours`}
        text={text.CONCOUR_BUTTON}
        icon={Cadeau}
      />
    </div>
  );
};

export default FooterButton;
