import React from "react";
import FooterGleeph from "../puresComponents/FooterGleeph";
import FooterB2B from "../puresComponents/FooterB2B";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";

export default function LegalNotice() {
  return (
    <section>
      <div className="legalNotice">MENTIONS LEGALES</div>
      <p className="legalNoticeSubtitle">EDITEURS :</p>
      <p className="legalNoticeText">
        <ul>
          <li>Sogedif, une société du groupe x </li>
          <li>Société par actions simplifiée au capital de XXX euros</li>
          <li> Immatriculée au RCS de Paris sous le n° XX</li>
          <li>Numéro de TVA intracommunautaire : XXX</li>
          <li>Adresse du Siège : X XX X XX, XX XXX</li>
          <li>
            Email : <a href="mailto:info@gleeph.com">info@gleeph.com</a>
          </li>
          <li> Coordonnées téléphoniques: XXXXX </li>
          <li>Directeur de publication : XX XX</li>
        </ul>
      </p>
      <p></p>
      <p className="legalNoticeSubtitle"> HEBERGEMENT :</p>
      <p className="legalNoticeText">
        Ce site est hébergé par la société Google Cloud France SARL, 8 Rue de
        Londres, 75009 PARIS.
      </p>
      <p className="legalNoticeText">
        Les serveurs de Google Cloud sont situés en Belgique.
      </p>
      <p className="legalNoticeText">
        L'utilisateur en ayant accès à ce site s'engage à se conformer aux
        présentes conditions d'utilisation.
      </p>
      <p className="legalNoticeSubtitle">DEVELOPPEMENT TECHNIQUE :</p>
      <p className="legalNoticeText">
        Ce site est développé par la société F-451, SAS au capital de 50 277,10
        euros, dont le siège social est situé 28 Bis Avenue de Strasbourg, 17340
        Châtelaillon-Plage, immatriculée au Registre du Commerce et des Sociétés
        de La Rochelle sous le numéro 808 534 465.
      </p>
      {/* <p className="legalNoticeSubtitle">DROITS D'AUTEUR :</p>
      <p className="legalNoticeText">
        Les marques et logos figurant sur ce site sont des marques déposées par
        F-451 ou les Sociétés du {cst.CLIENT_NAME} ou leurs partenaires. Leur
        mention n'accorde en aucune manière une licence ou un droit
        d'utilisation quelconque desdites marques, qui ne peuvent donc être
        utilisées sans le consentement préalable et écrit d’F-451 ou des
        partenaires.
        <br />
        <br />
        L'ensemble des informations présentes sur ce site peut être téléchargé,
        reproduit, imprimé sous réserve de :<br />
        - n'utiliser de telles informations qu'à des fins personnelles et en
        aucune manière à des fins commerciales;
        <br />
        - ne pas modifier de telles informations ;<br />
        - reproduire sur toutes copies la mention des droits d'auteur de F-451
        ou de ses partenaires.
        <br />
        <br />
        Toute autre utilisation est strictement interdite sans autorisation
        préalable et écrite de F-451.
      </p> */}
      <p className="legalNoticeSubtitle">CREDITS :</p>
      <p className="legalNoticeText">
        Visuels : FIGS SARL, 17 Rue des Panoyaux, 75020 Paris
      </p>
      <p className="legalNoticeSubtitle">PRESENTATION :</p>
      <p className="legalNoticeText">
        La web-app « XX par Gleeph » (ci-après « l’Application Web ou le Site »)
        est une application web gratuite accessible en ligne fournissant à
        l’utilisateur (« l’Utilisateur ») des recommandations de lecture.
      </p>
      <p className="legalNoticeText">
        L’Utilisateur peut également demander à recevoir par mail sa liste de
        recommandations.
      </p>

      <p className="legalNoticeSubtitle">NOTRE RESPONSABILITÉ :</p>
      <p className="legalNoticeText">
        Nous ne sommes pas responsables du contenu des sites Internet tiers vers
        lesquels renvoient des liens hypertextes présents sur le Site.
      </p>
      <p className="legalNoticeText">
        Du fait de la nature particulière du réseau Internet, l’accès au Site
        peut être interrompu ou restreint à tout moment par une cause
        indépendante de notre volonté. Dans ce cas, notre responsabilité ne
        pourra être recherchée.
      </p>
      <p className="legalNoticeText">
        Notre responsabilité ne saurait non plus être engagée en cas
        d’interruption d’accès au Site du fait d’opérations de maintenance, de
        mises à jour ou d’améliorations techniques, ou pour en faire évoluer la
        présentation.
      </p>
      <p className="legalNoticeSubtitle">PROPRIETE INTELLECTUELLE :</p>
      <p className="legalNoticeText">
        Nous sommes, conformément aux règles afférentes à la propriété
        intellectuelle, propriétaire ou licencié du Site, tant dans ses
        composantes techniques que graphiques, textuelles, ergonomiques, de
        navigation ou autres. Le Site est assuré au moyen de logiciels,
        d’algorithmes et de bases de données utilisés par nous sur lesquels nous
        disposons d’une licence de droits.
      </p>
      <p className="legalNoticeText">
        Le contenu mis en ligne appartient à Sogedif ou à la Société F-451 ou à
        leurs partenaires. L’utilisation du Site ne donne aucun droit aux
        Utilisateurs sur l’un quelconque des éléments cités au présent article.
      </p>
      <p className="legalNoticeText italic">Licence d’utilisation du Site :</p>
      <p className="legalNoticeText">
        Nous vous accordons une licence non exclusive, personnelle et non
        transférable vous autorisant à utiliser le Site, ainsi que les données
        qu’ils contiennent, pour votre usage strictement personnel, aux seules
        fins d’utilisation de celui-ci dans les conditions conformes à sa
        destination.
      </p>
      <p className="legalNoticeText">
        Vous reconnaissez en conséquence que toute autre exploitation du Site et
        de ses contenus est exclue du domaine de la présente licence et ne
        pourra être effectuée qu’après avoir obtenu notre autorisation écrite et
        préalable.
      </p>
      <div className="separateLineStyle" />
      <FooterB2B />
      <div className="separateLineStyle" />
      <FooterGleeph />
      <div className="separateLineStyle" />
      <ReglementConcours />
      <MentionsLegales />
      <PolitiqueDeConfident />
    </section>
  );
}
