import { useNavigate } from "react-router-dom";
import text from "../utils/lang/lang";
import cst from "../utils/constant";

const ReglementConcours = () => {
  const navigate = useNavigate();

  return (
    <div className="reglementConcoursContainer">
      <div
        onClick={() => navigate(`/${cst.ID_STORE}/concoursNotice`)}
        className="mentionLegalesPolice"
      >
        {text.REGLEMENT_CONCOURS}
      </div>
    </div>
  );
};

export default ReglementConcours;
