import React from "react";
import FooterGleeph from "../puresComponents/FooterGleeph";
import FooterB2B from "../puresComponents/FooterB2B";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";

export default function Politique() {
  return (
    <section>
      <div className="legalNoticeContainer">
        <div className="legalNotice">Politique de Confidentialité</div>
        <br />
        <p className="legalNoticeLittleInfo">
          Les traitements de données personnelles suivants sont susceptibles
          d’être mis en œuvre lors de votre utilisation de la web-application
          « X par Gleeph » développée à l’occasion du salon XX se tenant du
          vendredi 31 mars 2023 au dimanche 2 avril 2023 (« le Salon »)
        </p>
        <div className="legalNoticeSubtitle">
          INFORMATIONS CONCERNANT L’INSCRIPTION À LA NEWSLETTER « X »
        </div>
        <p className="legalNoticeText">
          En cochant la case d’inscription à la newsletter, les informations
          recueillies directement auprès de vous sont enregistrées dans la base
          de données gérée par X [1] et font l’objet d’un traitement automatisé
          ayant pour finalité de vous adresser des actualités et des offres, de
          vous proposer du contenu adapté à votre profil et à vos goûts, et de
          gérer sa relation avec vous.
        </p>
        <p className="legalNoticeText">
          Ces informations sont à destination exclusive de X et des maisons
          d’édition du groupe X [2] et seront conservées pendant trois (3) ans à
          compter de votre dernière visite.
        </p>
        <p className="legalNoticeSubtitle">
          INFORMATION CONCERNANT LA PARTICIPATION A LA LOTERIE
        </p>
        <p className="legalNoticeText">
          En cas de gain à la loterie, votre adresse mail est collectée et
          traitée par la société Sogedif en qualité de responsable de
          traitement, et par la société F451 (éditeur de la technologie « Gleeph
          »), en qualité de sous-traitant, comme moyen de preuve de votre gain
          et pour les seuls besoins d’attribution de votre lot. A défaut, nous
          ne serons pas en mesure de vous attribuer celui-ci.
        </p>
        <p className="legalNoticeText">
          Cette information est à destination exclusive de X et sera supprimée
          directement après le Salon.
        </p>
        <p className="legalNoticeSubtitle">
          INFORMATION CONCERNANT L’ENVOI DES RECOMMANDATIONS DE LECTURE PAR MAIL
        </p>
        <p className="legalNoticeText">
          En demandant à recevoir votre liste de recommandations de lectures par
          mail, vous acceptez le traitement de votre adresse mail par X, en
          qualité de responsable de traitement, et par la société F451 (éditeur
          de la technologie « Gleeph »), en qualité de sous-traitant.
        </p>
        <p className="legalNoticeText">
          Votre adresse mail est utilisée aux seules fins d’envoi de vos
          recommandations de lecture, et sera supprimée au plus tard lors du
          dernier jour du Salon.
        </p>
        <p className="legalNoticeSubtitle">EXERCICES DE VOS DROITS</p>
        <p className="legalNoticeText">
          Conformément à la loi Informatique et Libertés du 6 janvier 1978
          modifiée et au Règlement (UE) 2016/679 relatif à la protection des
          données à caractère personnel, vous disposez des droits suivants sur
          vos données : droit d’accès, droit de rectification, droit à
          l’effacement (droit à l’oubli), droit d’opposition, droit à la
          limitation du traitement, droit à la portabilité. Vous pouvez
          également définir des directives relatives à la conservation, à
          l'effacement et à la communication de vos données à caractère
          personnel après votre décès.{" "}
        </p>
        <p className="legalNoticeText">
          Pour exercer vos droits, merci d’adresser votre demande à l’adresse
          électronique suivante : <a href="mailto:X@X.com">X@X.com</a>
        </p>
        <p className="legalNoticeText">
          Toute demande de suppression de l’adresse mail intervenant avant la
          récupération de votre lot emportera annulation de votre gain.{" "}
        </p>
        <p className="legalNoticeText">
          Nous prenons les dispositions nécessaires pour que vos données à
          caractère personnel recueillies ne soient ni perdues, ni détournées,
          ni consultées, ni modifiées, ni divulguées par des tiers non
          autorisés. En cas de manquement aux dispositions ci-dessus, vous avez
          le droit d’introduire une réclamation auprès de la CNIL.
        </p>
        <p className="legalNoticeText">[1]- X</p>
        <p className="legalNoticeText">[2]- X</p>
      </div>
      <div className="separateLineStyle" />
      <FooterB2B />
      <div className="separateLineStyle" />
      <FooterGleeph />
      <div className="separateLineStyle" />
      <ReglementConcours />
      <MentionsLegales />
      <PolitiqueDeConfident />
    </section>
  );
}
