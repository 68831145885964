import React, { useEffect, useState } from "react";
import FooterGleeph from "../puresComponents/FooterGleeph";
import helpers from "../utils/APIHelper";
import { clearImgs } from "../utils/functions";
import Back from "../puresComponents/Back";
import FooterB2B from "../puresComponents/FooterB2B";
import FooterButton from "../puresComponents/FooterButton";
import ResultWithReco from "../components/ResultWithReco";
import ResultWithoutReco from "../components/ResultWithoutReco";
import { BookConsumerHook } from "../stores/bookStore";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";

export default function ListResult() {
  const [
    {
      bookReco,
      bookSearch,
      bookAuthor,
      bookSerie,
      authorRef,
      glidRef,
      themeRef,
      search,
      typeOfSearch,
      needLoads,
      isRegistered,
    },
    dispatch,
  ] = BookConsumerHook();

  const params = new URLSearchParams(window.location.search);
  const [getResponseAPI, setGetResponseAPI] = useState(false);
  const [coupDeCoeur, setCoupDeCoeur] = useState([]);
  const [loading, setLoading] = useState(needLoads ? true : false);

  useEffect(() => {
    // Check Store and use it if not empty
    if (needLoads) {
      const fetchData = async () => {
        const book = params.get("book");
        const author = params.get("author");
        const theme = params.get("theme");

        const res = await helpers.fetchGetDatas(book, author, theme);

        if (typeof res === "object" && "recos" in res) {
          const recos_w_validurl = await clearImgs(res["recos"]["recos"], true);
          const same_author_w_validurl = await clearImgs(
            res["books_by_author"]["authors_books"]
          );
          const same_serie_w_validurl = await clearImgs(
            res["books_by_serie"]["series_books"]
          );

          let tempSearch = book;
          let tempTypeOfSearch = "book";
          if (author !== "") {
            tempTypeOfSearch = "author";
            tempSearch = author;
          }
          if (theme !== "") {
            tempTypeOfSearch = "theme";
            tempSearch = theme;
          }
          addStateAndStore(
            recos_w_validurl,
            res["recos"]["input_book"][0],
            same_author_w_validurl,
            same_serie_w_validurl,
            res["author_search"],
            book,
            themeRef,
            tempSearch,
            tempTypeOfSearch
          );

          const stockEan = res["recos"].map((book) => book.id_ean);
          const bookTrack = res["my_book"] ? res["my_book"]["id_ean"] : book;
          helpers.fetchTrackReco(bookTrack, author, stockEan);
        }
      };
      fetchData();
    }
  }, []);

  async function getCoupDeCoeur() {
    const res = await helpers.fetchCoupDeCoeur();
    setCoupDeCoeur(res);
  }

  function sendUserTracking(element = null, type = "from_reco_to_book_detail") {
    helpers.fetchTrackSearch(type, element);
  }

  function addStateAndStore(
    book_reco,
    book_search,
    book_author,
    book_series,
    authorRef,
    glidRef,
    themeRef,
    search,
    typeOfSearch
  ) {
    const bk_search = book_search === undefined ? [] : book_search;
    const auth_search = authorRef === undefined ? [] : authorRef;
    if (
      book_reco != null &&
      book_reco !== "Mot Invalide" &&
      book_reco.length !== 0
    ) {
      dispatch({
        type: "addData",
        newBookReco: book_reco.filter((book) => book.imgs),
        newBookSearch: bk_search,
        newBookAuthor: book_author.filter((book) => book.imgs),
        newBookSerie: book_series.filter((book) => book.imgs),
        newAuthorRef: auth_search,
        newGlidRef: glidRef,
        newThemeRef: themeRef,
        newSearch: search,
        newTypeOfSearch: typeOfSearch,
        newNeedLoads: false,
      });
    } else {
      getCoupDeCoeur();
    }
    setGetResponseAPI(true);
    setLoading(false);
  }

  return (
    <div>
      <section style={{ paddingBottom: 80 }}>
        <Back />
        {getResponseAPI && bookReco.length === 0 && !loading ? (
          <ResultWithoutReco coupDeCoeur={coupDeCoeur} />
        ) : (
          <div>
            <ResultWithReco
              bookStock={bookReco}
              loading={loading}
              typeOfSearch={typeOfSearch}
              search={search}
              bookAuthor={bookAuthor}
              bookSearch={bookSearch}
              bookSerie={bookSerie}
              glidRef={glidRef}
              authorRef={authorRef}
              themeRef={themeRef}
              sendUserTracking={sendUserTracking}
            />
            <div className="separateLineStyle" />
          </div>
        )}
        <FooterB2B />
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div className="separateLineStyle" />
        <ReglementConcours />
        <MentionsLegales />
        <PolitiqueDeConfident />
      </section>
      {bookReco.length > 0 && !isRegistered && (
        <div>
          <div>
            <FooterButton glidRef={glidRef} typeOfSearch={typeOfSearch} />
          </div>
        </div>
      )}
    </div>
  );
}
