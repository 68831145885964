import React, { useEffect, useState } from "react";
import helpers from "../utils/APIHelper";
import { useSearchParams } from "react-router-dom";
import BookList from "../components/BookList";
import FooterGleeph from "../puresComponents/FooterGleeph";
import Lottie from "lottie-react";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";
import logojaime from "../images/J'aime.svg";
import Back from "../puresComponents/Back";
import { BookConsumerHook } from "../stores/bookStore";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";
import FooterButton from "../puresComponents/FooterButton";
import text from "../utils/lang/lang";
import FooterB2B from "../puresComponents/FooterB2B";
import NewSearch from "../puresComponents/NewSearch";

export default function RecoBooks() {
  const [searchParams, setSearchParams] = useSearchParams();
  let book = searchParams.get("book");
  let author = searchParams.get("author");
  let theme = searchParams.get("theme");
  let type = searchParams.get("type");

  const [{ bookReco, isRegistered }, dispatch]: any = BookConsumerHook();
  const [loading, setLoading] = useState(bookReco.length > 0 ? false : true);

  useEffect(() => {
    if (bookReco.length > 0) {
      setLoading(false);
    } else {
      callAPI();
    }
  }, []);

  async function callAPI() {
    const res = await helpers.fetchGetRecos(book, author, theme);
    addBookToList(res["recos"]);
    setLoading(false);
  }

  function addBookToList(bookReco) {
    if (
      bookReco != null &&
      bookReco !== "Mot Invalide" &&
      bookReco.length !== 0
    ) {
      dispatch({
        type: "addData",
        newBookReco: bookReco,
        newBookSearch: [],
        newBookAuthor: [],
        newBookSerie: [],
        newAuthorRef: [],
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
    }
  }

  const RecoWithResult = () => {
    return (
      <div>
        {loading ? (
          <div className="TopWaitTextReco">
            <span className="TopTextReco forceWidth">{text.LOAD_BOOKS}</span>
            <Lottie
              animationData={animationLoading}
              loop={true}
              style={{
                width: "50px",
                margin: "auto",
                marginBottom: "16px",
              }}
            />
          </div>
        ) : (
          <div className="containerListResultWoMargin">
            <div style={{ marginBottom: 16 }}>
              <div>
                <span className="midTextReco">
                  <div className="logoJaimeContainer">
                    <img
                      src={logojaime}
                      alt="logojaime"
                      className="imageJaime"
                    />
                    {text.TITLE_RUBRIQUE_RECOS}
                  </div>
                </span>
              </div>
            </div>
            <div className="separateLineStyle" />
            <div style={{ marginTop: 16 }}>
              {bookReco.length > 0 && (
                <BookList listBook={bookReco} key={Math.random()} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <section>
        <Back />
        <RecoWithResult />
        <div className="separateLineStyle" />
        <div className="NewSearchContainer">
          <NewSearch />
        </div>
        <div className="separateLineStyle" />
        <FooterB2B />
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div className="separateLineStyle" />
        <ReglementConcours />
        <MentionsLegales />
        <PolitiqueDeConfident />
      </section>
      {bookReco.length > 0 && !isRegistered && (
        <div>
          <FooterButton glidRef={book} typeOfSearch={type} />
        </div>
      )}
    </div>
  );
}
